<template>
  <div>
    <CRow>
      <CCol sm="12">
        <TicketDetails :login="true"/>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import TicketDetails from '../../components/tickets/TicketDetails.vue'
import tickets from '../../services/tickets';
import store from '../../store'

export default {
  name: 'Ticket',
  components: { TicketDetails },
  data: function () {
		return {
        code: 'active',
        title: 'Reportes Activos',
        tickets: []
    }
  },
  mounted: async function() {
    this.loading();
    
    this.loaded();
  },
  methods: {
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
